import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'direct-debit-channel',
  templateUrl: './direct-debit-channel.component.html',
  styleUrls: ['./direct-debit-channel.component.scss']
})
export class DirectDebitChannelComponent implements OnInit {

  cardPaymentForm: FormGroup;
  pinSection: boolean = false;
  isFilling: boolean = false;

  constructor(private formBuilder: FormBuilder) {
    this.cardPaymentForm = this.formBuilder.group({
      cardNo: new FormControl('', [Validators.required]),
      expiryDate: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
      pin: new FormControl('', [Validators.required])
    });
  }

  ngOnInit(): void {
  }

  enterPin() {
    this.pinSection = true;
  }

  onSubmitBtnClicked() {}

}
