
<div class="d-flex flex-column justify-content-between ps-3 pe-3">
    <ng-container *ngIf="!state.verifyingQR && !state.error">
      <div class="qr-container">
        <div>
          <div class="qr-container__header">
            <div class="instruction">Scan the QR code below in your Access, Ecobank, Fidelity, First Bank, Standard Chartered and Zenith Bank Mobile app complete the payment.
            </div>
            </div>
            <div class="qr__wrapper">
              <div class="qr__code">
                <qrcode
                  [allowEmptyString]="true"
                  [qrdata]="qrCodeValue"
                  [cssClass]="'center'"
                  [elementType]="'img'"
                  [errorCorrectionLevel]="'H'"
                  (qrCodeURL)="onChangeURL($event)"
                  [margin]="4"
                  [scale]="1"
                  [width]="214"
                ></qrcode>
              </div>
            </div>
        </div>
      </div>
      <div class="qr-download">
        <div>
          <div>
            <div class="qr-download">
              <a [href]="qrCodeDownloadLink" class="omni-btn omni-btn-transparent" download="qrImage">
                <span class="d-inline-block ps-2 fz-12 text-underline">Save QR image</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="confirm-payment">
        <button class="omni-btn omni-btn-primary" (click)="confirmTransaction()" [disabled]="error">I have made
          payment</button>
      </div>
      <div class="expiry-information">
        <span class="d-inline-block expiry-information__countdown">
          QR&nbsp;{{ error ? 'expired' : ' will expire in'}} &nbsp;
        </span>
        <span *ngIf="!error">5 minutes</span>
      </div>
    </ng-container>
  
    <ng-container *ngIf="state.verifyingQR && !state.error">
      <div class="verification-loader">
        <span class="omni-spinner-text">{{loadingMessage}}</span>
        <div class="omni-spinner-border" role="status">
        </div>
      </div>
    </ng-container>
    
    <ng-container *ngIf="state.error && !state.verifyingQR">
      <div class="omni-checkout-alert">
        <div class="text-center">
          <div class="custom-alert-icon mb-3">
            <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
          </div>
          <div class="px-3">
            <div class="fz-14 fw-bold pb-2">
              We could not process this transaction
            </div>
            <div class="w-full text-center pb-2 lh-2">
              <small class="fz-12">
                {{errorMessage}}
              </small>
            </div>
          </div>
          <!-- <div class="mt-4">
                              <button (click)="tryAgain()" class="omni-btn omni-btn-transparent">Try again</button>
                            </div> -->
        </div>
      </div>
    </ng-container>
  </div>