import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss']
})
export class SvgComponent implements OnInit {
  @Input() name!: string;
  @Input() svgClass: any;

  constructor() { }

  ngOnInit(): void {
  }

}
