
                        <form [formGroup]="cardPaymentForm" (ngSubmit)="onSubmitBtnClicked()">
                            <div class="row pt-4">
                              <div class="form-group col-md-12 mb-3">
                                <label class="omni-label">Account Number</label>
                                <div class="group-input">
                                  <input class="omni-form-control" name="accountNo" type="text" placeholder="" maxlength="10"
                                    required />
                                </div>
                              </div>
                              <div class="form-group col-mb-12 mb-2">
                                <label class="omni-label">Select Bank</label>
                                <div class="group-input">
                                  <select class="omni-form-control" name="bank"
                                    aria-label="bank" placeholder="Select user bank" required>
                                    <option selected>Select user bank</option>
                                    <option value="1">Stanbic IBTC Bank</option>
                                    <option value="2">Access Bank</option>
                                    <option value="3">Wema Bank</option>
                                  </select>
                                </div>
                              </div>
                              <div class="form-group col-md-12 mb-3">
                                <label class="omni-label">Account Name</label>
                                <div class="group-input">
                                  <input class="omni-form-control" name="accountName" type="text" placeholder="" required>
                                </div>
                              </div>
                              <div class="form-group col-md-12 mt-2">
                                <div class="group-input">
                                  <button class="omni-btn omni-btn-primary omni-btn-block" type="submit"
                                    (click)="isFilling = false; pinSection = true;">Continue</button>
                                </div>
                              </div>
                            </div>
                          </form>