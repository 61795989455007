import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Subject, takeUntil, timer } from 'rxjs';
import { TransactionData } from 'src/app/api-request';
import { ApiResponse, TransactionFees } from 'src/app/api-response';
import { AppService } from 'src/app/app.service';
import { StringHelper } from 'src/app/strings-helper';

@Component({
  selector: 'visa-qr-channel',
  templateUrl: './visa-qr-channel.component.html',
  styleUrls: ['../qr-channel.component.scss']
})
export class VisaQrChannelComponent implements OnInit, AfterViewInit, OnDestroy {

  @Output() onComplete: any = new EventEmitter<any>();
  @Output() onClose: any = new EventEmitter<any>();
  @Input() activeTab: any = null;
  error: boolean = false;

  verifyingQR: boolean = false;
  inProcess = true;
  public qrCodeValue: string = '';
  public qrCodeDownloadLink: SafeUrl = '';

  state = {
    visible: false,
    qrTypeSelected: false,
    error: false,
    verifyingQR: false
  };
  responseData: any;
  transactionData!: TransactionData;
  amount: any;
  transactionFees!: TransactionFees;
  reference!: string;
  errorMessage!: string;

  time_in_minutes = 5;

  display: string = `${5} minutes`;
  public timerInterval: any;
  QRExpired: boolean = false;
  loadingMessage: string = 'Please wait';

  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  private timer$: any;
  private destroy$ = new Subject();

  constructor(
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private el: ElementRef
    ) {
      // listen for nqr request completion before proceeding with visa-qr request
      this.awaitNqrRequest();
    }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.transactionFees = res.transactionFees
        this.amount = StringHelper.toMajorAmount(+this.transactionData.amount + this.transactionFees.WEB_QR);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  awaitNqrRequest() {
    this.appService.getRequestCompleteObservable()
    .pipe(takeUntil(this.destroy$))
    .subscribe(() => {      
      this.generateQrCode(this.transactionData);
    });
  }

  startCountDown() {
    this.QRExpired = false;
    this.timer(this.time_in_minutes);
  }

  stopCountDown() {
    clearInterval(this.timerInterval);
  }

  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.QRExpired = true;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  generateQrCode(r: TransactionData) {
    this.setState('error', false);
    this.setState('verifyingQR', true);
    var data: any = {
      transactionReference: this.reference,
      qrRequestType: 'GENERATE_QR'
    };
    this.appService.generateQRCode(data).subscribe({
      next: (res) => {
        if (res.success) {
          this.qrCodeValue = res.data.qrCode;
          this.setState('verifyingQR', false);
          this.setState('visible', true);
        } else {
          this.setState('verifyingQR', false);
          this.setState('error', true);
          this.errorMessage =
            'An error occurred while generating QR, please try again';
        }
      },
      error: (err) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingQR', false);
        this.setState('error', true);
        this.stopCountDown();
      },
    });
  }

  confirmTransaction() {
    this.setState('verifyingQR', true);
    var qrReq: any = {
      transactionReference: this.reference,
      qrRequestType: 'CONFIRM_QR_PAYMENT'
    };
    this.appService.queryQRransaction(qrReq).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingQR', false);
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          if (errorMessage == "PENDING_CONFIRMATION") {
            this.beginQuery();
            return;
          }
          this.setState('verifyingQR', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingQR', false);
        this.setState('error', true);
      },
    });
  }

  beginQuery() {
    this.startInterval();
  }

  startInterval() {
    this.timer$ = timer(0, 5000)
    .subscribe((tick: any) => {
      this.loadingMessage = `Confirming Transaction Status.`
      if (tick == 10) {
        this.loadingMessage = `It's taking longer than expected to confirm your payment.`
        this.errorMessage = `We could not confirm your payment.`
        setTimeout(() => {
          this.setState('verifyingQR', false);
          this.setState('error', true);
        }, 5000);
      }
      else if (tick == 12) {
        this.stopInterval();
        // this.onClose.emit();
      }
      else {
      // Your API call, which will be performed every 5000 milliseconds
        this.queryTransaction();
      }
    });
  }

  stopInterval() {
    this.timer$.unsubscribe();
  }

  queryTransaction() {
    this.setState('verifyingQR', true);
    var qrReq: any = {
      transactionReference: this.reference,
      qrRequestType: 'CONFIRM_QR_PAYMENT'
    };
    this.appService.queryQRransaction(qrReq).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingQR', false);
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          if (errorMessage == "PENDING_CONFIRMATION") {
              return;
          }
          this.setState('verifyingQR', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingQR', false);
        this.setState('error', true);
      },
    });
  }

  verifyQR() {
    this.verifyingQR = true;
    setTimeout(() => {
      this.verifyingQR = false;
      this.inProcess = false;
    }, 2000);
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  paymentDone() {
    this.onComplete.emit();
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
    this.destroy$.next(null);
    this.destroy$.complete();
  }

}
