import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import * as loadjs from 'loadjs';
import { NgxOtpInputConfig } from 'ngx-otp-input';
import { Subject, timer } from 'rxjs';
import { TransactionData } from '../api-request';
import { ApiResponse, TransactionFees } from '../api-response';
import { AppService } from '../app.service';
import { BaseUrl } from '../baseurl';
import { StringHelper } from '../strings-helper';

declare var dftp: any;
@Component({
  selector: 'card-channel',
  templateUrl: './card-channel.component.html',
  styleUrls: ['./card-channel.component.scss'],
})
export class CardChannelComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Output() onRedirect: any = new EventEmitter<any>();
  @Output() onComplete: any = new EventEmitter<any>();
  @Input() activeTab: any = null;

  cardType: any;
  cardPaymentForm: FormGroup;
  otpInputConfig: NgxOtpInputConfig = {
    otpLength: 6,
    autofocus: true,
    classList: {
      input: 'my-super-class',
      inputFilled: 'my-super-filled-class',
      inputDisabled: 'my-super-disable-class',
      inputSuccess: 'my-super-success-class',
      inputError: 'my-super-error-class',
    },
  };
  otpCode!: string;
  // 10 minutes from now
  time_in_minutes = 5;

  display: string = `${5} minutes`;
  public timerInterval: any;
  paymentCard: any;

  cards: any[] = [
    {
      cardName: 'Ridwan Odebiyi',
      cardNumber: '**** **** **** 7878',
    },
    {
      cardName: 'Ridwan Odebiyi',
      cardNumber: '**** **** **** 7878',
    },
  ];
  cardChecked: boolean = false;

  transactionData!: TransactionData;
  amount: any;
  transactionFees!: TransactionFees;
  // verifyingTransfer: boolean = false;

  state = {
    visible: false,
    isInitialized: false,
    completed: false,
    isFilling: true,
    verifyingTransfer: false,
    showSavedCard: false,
    pinSection: false,
    loaded: false,
    error: false,
  };
  responseData: any;
  reference!: string;
  errorMessage!: string;
  loading: boolean = false;
  visaPin: string = '1234'
  showPin: boolean = false;
  encryptCard: boolean = true;
  tokenizeCard: boolean = false;
  hasSavedCard: boolean = false;
  private scriptLoaded$ = new Subject<void>();
  profilerOptions = {
    attemptReference: '', // inserted by the backend
    sensitiveFields: ['cvv', 'pan', 'pin', 'exp'], // list of sensitive fields
  };
  // @ViewChild('iframe', { static: true }) iframe!: ElementRef;
  iframe!: HTMLIFrameElement;
  cardinalCallbackUrl: string = 'https://uat.digicoreltd.com/loader.html'; //'https://api.dev.gateway.zestpayment.com/loader.html';
  queryTime: number = 60; // 5 minutes

  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  private timer$: any;

  trustedHTML: any

  @ViewChild('myDiv', { static: false }) myDiv!: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private el: ElementRef,
    private appService: AppService,
    private cd: ChangeDetectorRef,
    private sanitizer: DomSanitizer,
    private renderer: Renderer2
  ) {
    this.cardPaymentForm = this.formBuilder.group({
      cardNo: new FormControl('', [Validators.required]),
      expiryDate: new FormControl('', [Validators.required]),
      cvv: new FormControl('', [Validators.required]),
      pin: new FormControl('', [Validators.required]),
    });
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.state.visible) {
      if (changes['activeTab'].currentValue == 'card-tab') {
        this.setState('isFilling', true);
        this.setState('error', false);
        this.cardType = '';
        this.showPin = false;
        this.formatDigits();
      }
    }
  }

  ngAfterViewInit(): void {
    this.formatDigits();
    this.startNethoneScript();
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.hasSavedCard = res.savedCard
        this.transactionFees = res.transactionFees
        
        this.amount = StringHelper.toMajorAmount(+this.transactionData.amount + this.transactionFees.WEB_CARD);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  ngOnInit(): void {}

  onSubmitBtnClicked() {
    this.loading = true;
    const cardValue = this.cardPaymentForm.value;

    const authData = {
      pan: StringHelper.formatCardPan(cardValue.cardNo, 19),
      pin: cardValue.pin,
      cvv2: cardValue.cvv,
      expiryDate: this.refineValue(cardValue.expiryDate),
    };
    
    const data: any = {
      cardRequestType: 'INITIATE_CARD_PAYMENT',
      email: this.transactionData.email,
      transactionRef: this.reference,
      currency: this.transactionData.currency
    };

    if (this.tokenizeCard) {
      data['tokenizeCard'] = this.tokenizeCard;
      data['merchantApiKey'] = this.transactionData.key;
    }

    let cardData;

    if (this.encryptCard) {
      if (this.cardType == 'visa') {
        const deviceInformation = {
          httpBrowserLanguage: navigator.language,
          httpBrowserJavaEnabled: navigator.javaEnabled() ? true : false,
          httpBrowserJavaScriptEnabled: true,
          httpBrowserColorDepth: screen.pixelDepth,
          httpBrowserScreenHeight: screen.height,
          httpBrowserScreenWidth: screen.width,
          httpBrowserTimeDifference: new Date().getTimezoneOffset(),
          userAgentBrowserValue: navigator.userAgent,
          deviceChannel: "browser"
        }
        cardData = { 
          ...data, 
          authData: StringHelper.encryptFun(JSON.stringify(authData)), 
          deviceInformation: deviceInformation
        };
      }
      else {
        cardData = {
          ...data,
          authData: StringHelper.encryptFun(JSON.stringify(authData)),
        };
      }
    } else {
      cardData = { ...data, ...authData };
    }
    this.sendProfile();
    this.appService.initiateCardPayment(cardData).subscribe({
      next: (res) => {
        this.loading = false;
        if (res.success) {
          switch (res.data.code) {
            case '00':
              this.paymentDone();
              break;
            case '01':
              this.setState('pinSection', true);
              break;
            case '02':
              this.openVisaForm(res.data);
              break;
            case '03':
              this.authenticateMasterCardPayment(authData);
              break;
            default:
              this.errorMessage =
                'We are unable to complete your request at this time, Please try again later';
              this.setState('error', true);
              break;
          }
        } else {
          this.errorMessage = StringHelper.getErrorMessage(res);
          this.setState('isFilling', false);
          this.setState('error', true);
          this.cardPaymentForm.reset();
        }
      },
      error: (err) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.loading = false;
        this.setState('isFilling', false);
        this.setState('error', true);
        this.cardPaymentForm.reset();
      },
    });
  }
  getProfileReference() {
    const user = {
      email: this.transactionData.email,
    };
    this.appService.profileUser(user).subscribe({
      next: (res) => {
        this.profilerOptions.attemptReference = res.data.attemptRef;
        this.initProfiler();
      },
      error: (err) => {
        console.log('Error getting attempt ref', err);
      },
    });
  }
  startNethoneScript() {
    loadjs( this.appService.NETHONE_SCRIPT_URL, {
      returnPromise: true,
      before: (path, scriptEl: any) =>
        (scriptEl.crossOrigin = 'use-credentials'),
      success: () => {
        console.log('Script loaded!');
        this.getProfileReference();
      },
      error: () => {
        console.log('Error loading script.');
      },
    });
  }
  
  initProfiler() {
    dftp.init(this.profilerOptions);

    console.log('Profiled user initialized ');
  }
  sendProfile() {
    dftp
      .profileCompleted()
      .catch((err: any) =>
        console.error('Profile completion failed with err: ' + err)
      );
    dftp.validateIntegration().then((res: any) => {
      console.error('Profiled user result: ' + res);
    });
    dftp.finishBehavGathering()
  }

  refineValue(value: string) {
    const split = value.split('/');
    return split[1] + '' + split[0];
  }



  checkIframeContentChange() {
    // detecting if iframe URL has changed
    console.log('Content changed!');
    this.cd.detectChanges();
    this.confirmTransaction();
  }

  openVisaForm(data: any) {
    const iframe = document.createElement('iframe');
    iframe.setAttribute('frameBorder', '0'),
    iframe.setAttribute('allowtransparency', 'true'),
    iframe.setAttribute('id', 'cardinalTarget');
    const html = `<!DOCTYPE html> 
    <html lang="en"> 
    <head>
    <style> 
      #form1 {
        opacity:0
      } 
    </style>
    <meta charset="UTF-8"> 
    <meta name="viewport" content="width=device-width, initial-scale=1.0"> 
    <meta http-equiv="X-UA-Compatible" content="ie=edge"> 
    <title>Zest VISA Checkout</title> 
    </head> 
    <body>
    <form id="form1" action=${data.acsUrl} method="post">
      <input name="JWT" value=${data.jwt}>
      <input name="MD" value=${data.md}>
    </form>
    </body>
    <script type="text/javascript">
      var body = document.getElementsByTagName("body")[0];
      body.addEventListener("load", document.getElementById('form1').submit(), false);
    </script>
    </html>`;

    iframe.style.cssText =
      'z-index: 999999999999999;background: #FFF;border: 0px none transparent;overflow-x: hidden;overflow-y: hidden;margin: 0;padding: 0;-webkit-tap-highlight-color: transparent;-webkit-touch-callout: none;position: fixed;left: 0;top: 0;width: 100%;height: 100%';
    document.body.appendChild(iframe);
    iframe.src = 'about:blank';

    const handleLoad = () => {
      iframe.removeEventListener('load', handleLoad); // Remove the event listener after it fires
      this.checkIframeContentChange(); // detecting if iframe content has changed
    };

    iframe.addEventListener('load', handleLoad);
    const iWindow = iframe.contentWindow;
    iWindow?.document.open();
    iWindow?.document.write(html);
    iframe.onload = () => {
      // detecting if iframe content has changed
      this.checkIframeContentChange();
    };
  }

  authenticateMasterCardPayment(authData: any) {
    this.setState('verifyingTransfer', true);
    const data = {
      cardRequestType: "AUTHENTICATE_PAYER",
      transactionRef: this.reference,
      authData: StringHelper.encryptFun(JSON.stringify(authData)),
      browser: navigator.userAgent,
      threeDSecureChallengeWindowSize: "390_X_400",
      acceptHeaders: "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7",
      colorDepth: screen.pixelDepth,
      javaEnabled: navigator.javaEnabled() ? true : false,
      javaScriptEnabled: true,
      language: navigator.language,
      screenHeight: screen.height,
      screenWidth: screen.width,
      timeZone: new Date().getTimezoneOffset()
    }

    setTimeout(() => {
      this.continueAuthentication(data);
    }, 10000);
  }

  continueAuthentication(data: any) {
    this.appService.authenticateCardPayment(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.openMasterCardForm(res.data)
        }
        else {
          this.errorMessage = StringHelper.getErrorMessage(res);
          this.setState('verifyingTransfer', false);
          this.setState('error', true);
        }
      },
      error: (err: any) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  openMasterCardForm(data: any) {
    this.onRedirect.emit();
    this.trustedHTML = this.sanitizer.bypassSecurityTrustHtml(data.redirectHtml);
    const iframe = document.getElementById("cardinalTarget") as HTMLIFrameElement;
    iframe.style.cssText = "display: block;z-index: 999999999999999;background: #FFF;border: 0px none transparent;overflow-x: hidden;overflow-y: hidden;margin: 0;padding: 0;-webkit-tap-highlight-color: transparent;-webkit-touch-callout: none;position: fixed;left: 0;top: 0;width: 100%;height: 100%";
    iframe.src = 'about:blank';

    const handleLoad = () => {
      iframe.removeEventListener('load', handleLoad); // Remove the event listener after it fires
      this.checkIframeContentChange(); // detecting if iframe content has changed
    };

    iframe.addEventListener('load', handleLoad);

    const iWindow = iframe.contentWindow;
    iWindow?.document.open();
    iWindow?.document.write(data.redirectHtml);
    iWindow?.document.close();
    this.setState('loaded', true);
  }

  removeIFrame() {
    const frame = document.getElementById(
      'cardinalTarget'
    ) as HTMLIFrameElement;
    frame.parentNode?.removeChild(frame);
  }

  // confirmTransaction() {
  //   const data = {
  //     cardRequestType: 'CARD_PAYMENT_STATUS',
  //     transactionRef: this.reference,
  //   };
  //   this.appService.confirmCardPayment(data).subscribe({
  //     next: (res) => {
  //       this.verifyingTransfer = false;
  //     },
  //     error: (err) => {
  //       this.verifyingTransfer = false;
  //     },
  //   });
  // }

  confirmTransaction() {
    console.log('iframe-loaded');

    this.setState('verifyingTransfer', true);
    const data = {
      cardRequestType: 'CARD_PAYMENT_STATUS',
      transactionRef: this.reference,
    };
    this.appService.confirmCardPayment(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          if (res.data.narration == "IN_PROGRESS" || res.data.code == "99") {
            this.beginQuery();
            return;
          } else {
            this.setState('verifyingTransfer', false);
            this.removeIFrame();
            this.paymentDone();
          }
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  beginQuery() {
    this.startInterval();
  }

  startInterval() {
    this.timer$ = timer(0, 5000).subscribe((tick: any) => {
      console.log(tick);

      if (tick == this.queryTime) {
        this.errorMessage = `We could not confirm your payment.`;
        console.log(this.errorMessage);

        setTimeout(() => {
          this.setState('verifyingTransfer', false);
          this.setState('error', true);
        }, 5000);
      } else if (tick == (this.queryTime + 2)) {
        this.stopInterval();
        this.removeIFrame();
      } else {
        // Your API call, which will be performed every 10000 milliseconds
        this.queryTransaction();
      }
    });
  }

  stopInterval() {
    this.timer$.unsubscribe();
  }

  queryTransaction() {
    const data = {
      cardRequestType: 'CARD_PAYMENT_STATUS',
      transactionRef: this.reference,
    };
    this.appService.confirmCardPayment(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          if (res.data.narration == "IN_PROGRESS" || res.data.code == "99") {
              return;
          }
          else {
            this.setState('verifyingTransfer', false);
            this.removeIFrame();
            this.paymentDone();
          }
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  setTransaction() {
    this.setState('verifyingTransfer', true);
  const data = {
  cardRequestType: '',
  otp: this.otpCode,
  transactionRef: this.reference,
};

if (this.cardType == 'afrigo') {
  data['cardRequestType'] = 'PAYMENT_CONFIRMATION';
} else {
  data['cardRequestType'] = 'VALIDATE_CARD_OTP';
}
    
    this.appService.authenticateCardPayment(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.responseData = res.data;
          this.setState('verifyingTransfer', false);
          this.paymentDone();
        }
      },
      error: (err: any) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('pinSection', false);
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  formatTime(value: number) {
    `${Math.floor(value / 60)}:${('0' + (value % 60)).slice(-2)}`;
  }

  startCountDown() {
    this.timer(this.time_in_minutes);
  }

  stopCountDown() {
    clearInterval(this.timerInterval);
  }

  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  reInitialize() {
    this.stopCountDown();
    setTimeout(() => {
      this.startCountDown();
    }, 1000);
  }

  maskCustomerPhoneNumber(phoneNumber: string): string {
    if (phoneNumber.length === 11) {
      return `${phoneNumber.substring(0, 4)}****${phoneNumber.substring(
        8,
        11
      )}`;
    } else {
      return phoneNumber;
    }
  }

  maskCustomerEmail(email: string): string {
    let maskEmail = '';
    const prefix = email.substring(0, email.lastIndexOf('@'));
    const postfix = email.substring(email.lastIndexOf('@'));

    for (let i = 0; i < prefix.length; i++) {
      if (
        i === 0 ||
        (i === 1 && prefix.length >= 10) ||
        i === prefix.length - 1 ||
        (i === prefix.length - 2 && prefix.length >= 10)
      ) {
        maskEmail = maskEmail + prefix[i].toString();
      } else {
        maskEmail = maskEmail + '*';
      }
    }
    maskEmail = maskEmail + postfix;
    return maskEmail;
  }

  checkDigit(event: any) {
    const code = event.which ? event.which : event.keyCode;

    if ((code < 48 || code > 57) && code > 31) {
      return false;
    }

    return true;
  }

  getCardType(number: any) {
    // visa
    var re = new RegExp('^4[0-9]');
    if (number.match(re) != null) return 'visa';

    // Mastercard
    // Updated for Mastercard 2017 BINs expansion
    re = new RegExp('^(2[221-720][0-9])|(5[1-5][0-9])');
    if (number.match(re) != null) return 'mastercard';
    // Afrigo
    re = new RegExp('^564');
    if (number.match(re) != null && number.length === 18) return 'afrigo';

    // Verve
    if (number.length === 19) return 'verve';

    return '';
  }

  formatDigits() {
    let ccNumberInput = document.querySelector(
        '.cc-number-input'
      ) as HTMLInputElement,
      ccNumberPattern = /^\d{0,19}$/g,
      ccNumberSeparator = ' ',
      ccNumberInputOldValue: any,
      ccNumberInputOldCursor: any,
      ccExpiryInput = document.querySelector(
        '.cc-expiry-input'
      ) as HTMLInputElement,
      ccExpiryPattern = /^\d{0,4}$/g,
      ccExpirySeparator = '/',
      ccExpiryInputOldValue: any,
      ccExpiryInputOldCursor,
      mask = (value: string | any[], limit: number, separator: string) => {
        var output = [];
        for (let i = 0; i < value.length; i++) {
          if (i !== 0 && i % limit === 0) {
            output.push(separator);
          }

          output.push(value[i]);
        }

        return output.join('');
      },
      unmask = (value: string) => value.replace(/[^\d]/g, ''),
      checkSeparator = (position: number, interval: number) =>
        Math.floor(position / (interval + 1)),
      ccNumberInputKeyDownHandler = (e: { target: any }) => {
        let el = e.target;
        ccNumberInputOldValue = el.value;
        ccNumberInputOldCursor = el.selectionEnd;
      },
      ccNumberInputInputHandler = (e: { target: any }) => {
        let el = e.target,
          newValue = unmask(el.value),
          newCursorPosition;
          
        if (newValue.match(ccNumberPattern)) {
          newValue = mask(newValue, 4, ccNumberSeparator);

          newCursorPosition =
            ccNumberInputOldCursor -
            checkSeparator(ccNumberInputOldCursor, 4) +
            checkSeparator(
              ccNumberInputOldCursor +
                (newValue.length - ccNumberInputOldValue.length),
              4
            ) +
            (unmask(newValue).length - unmask(ccNumberInputOldValue).length);

          el.value = newValue !== '' ? newValue : '';
        } else {
          el.value = ccNumberInputOldValue;
          newCursorPosition = ccNumberInputOldCursor;
        }

        el.setSelectionRange(newCursorPosition, newCursorPosition);

        highlightCC(el.value);
      },
      highlightCC = (ccValue: any) => {
          
        // Remove spaces from the input value
        ccValue = ccValue.replace(/\s/g, '');
        
        let ccCardType = '',
          ccCardTypePatterns: any = {
            visa: /^4[0-9]{6,}$/,
            verve: /^506(0|1|2|3|4|5)[0-9]{12,15}$/,
            mastercard: /(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}/,
            afrigo: /^564[0-9]{15}/,
          };

        for (const cardType in ccCardTypePatterns) {
          if (ccCardTypePatterns[cardType].test(ccValue)) {
            ccCardType = cardType;
            break;
          }
        }
        this.cardType = ccCardType;
        
        switch (ccCardType) {
          case 'visa': 
          case 'mastercard':
            this.showPin = true;
            // this.cardPaymentForm.patchValue({
            //   pin: this.visaPin,
            // });
            break;
          case 'verve':
            this.showPin = true;
            break;
          case 'afrigo':
            this.showPin = true;
            break;
          default:
            break;
        }
      },
      ccExpiryInputKeyDownHandler = (e: { target: any }) => {
        let el = e.target;
        ccExpiryInputOldValue = el.value;
        ccExpiryInputOldCursor = el.selectionEnd;
      },
      ccExpiryInputInputHandler = (e: { target: any }) => {
        let el = e.target,
          newValue = el.value;

        newValue = unmask(newValue);
        if (newValue.match(ccExpiryPattern)) {
          newValue = mask(newValue, 2, ccExpirySeparator);
          el.value = newValue;
        } else {
          el.value = ccExpiryInputOldValue;
        }
      };

    ccNumberInput?.addEventListener('keydown', ccNumberInputKeyDownHandler);
    ccNumberInput?.addEventListener('input', ccNumberInputInputHandler);

    ccExpiryInput?.addEventListener('keydown', ccExpiryInputKeyDownHandler);
    ccExpiryInput?.addEventListener('input', ccExpiryInputInputHandler);
  }

  validateCardNumber(number: string) {
    //Check if the number contains only numeric value
    //and is of between 13 to 19 digits
    const regex = new RegExp('^[0-9]{13,19}$');
    if (!regex.test(number)) {
      return false;
    }

    return this.luhnCheck(number);
  }

  luhnCheck = (val: string) => {
    let checksum = 0; // running checksum total
    let j = 1; // takes value of 1 or 2

    // Process each digit one by one starting from the last
    for (let i = val.length - 1; i >= 0; i--) {
      let calc = 0;
      // Extract the next digit and multiply by 1 or 2 on alternative digits.
      calc = Number(val.charAt(i)) * j;

      // If the result is in two digits add 1 to the checksum total
      if (calc > 9) {
        checksum = checksum + 1;
        calc = calc - 10;
      }

      // Add the units element to the checksum total
      checksum = checksum + calc;

      // Switch the value of j
      if (j == 1) {
        j = 2;
      } else {
        j = 1;
      }
    }

    //Check if it is divisible by 10 or not.
    return checksum % 10 == 0;
  };

  setOTP(otp: string[]) {
    this.otpCode = otp.join('');
  }

  onVerify() {
    this.setTransaction();
  }

  paymentDone() {
    this.onComplete.emit();
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  verifyTransfer() {
    this.setState('verifyingTransfer', true);
    setTimeout(() => {
      this.paymentDone();
    }, 3000);
  }

  enterPin() {
    this.state.pinSection = true;
  }

  tryAgain() {
    this.setState('error', false);
  }

  saveCard(event: any) {
    const checked = event.target.checked;
    this.tokenizeCard = checked ? true : false;
  }

  fetchSavedCards() {
    this.setState('verifyingTransfer', true);
    const data = {
      email: this.transactionData.email,
      merchantApiKey: this.transactionData.key,
    };
    this.appService.fetchSavedCards(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.cards = res.data;
          console.log(this.cards);
          
          this.setState('verifyingTransfer', false);
          this.setState('showSavedCard', true);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  cardSelected(event: any) {}

  confirmPayment() {
    this.setState('showSavedCard', false);
    this.setState('verifyingTransfer', true);

    const data: any = {
      cardRequestType: 'TOKEN_PAYMENT',
      email: this.transactionData.email,
      transactionRef: this.reference,
      currency: this.transactionData.currency,
      cardId: this.paymentCard.cardId,
    };

    this.appService.confirmTokenizedCardPayment(data).subscribe({
      next: (res) => {
        if (res.success) {
          this.loading = false;
          switch (res.data.code) {
            case '00':
              this.paymentDone();
              break;
            case '01':
              this.setState('verifyingTransfer', false);
              this.setState('pinSection', true);
              break;
            case '02':
              this.setState('verifyingTransfer', false);
              this.openVisaForm(res.data);
              break;
            default:
              this.errorMessage =
                'We are unable to complete your request at this time, Please try again later';
              this.setState('verifyingTransfer', false);
              this.setState('error', true);
              break;
          }
        } else {
          this.errorMessage = StringHelper.getErrorMessage(res);
          this.setState('verifyingTransfer', false);
          this.setState('isFilling', false);
          this.setState('error', true);
          this.cardPaymentForm.reset();
        }
      },
      error: (err) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingTransfer', false);
        this.setState('isFilling', false);
        this.setState('error', true);
        this.cardPaymentForm.reset();
      },
    });
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
    this.scriptLoaded$.complete();
  }
}

