import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { CardChannelComponent } from './card-channel/card-channel.component';
import { TransferChannelComponent } from './transfer-channel/transfer-channel.component';
import { DirectDebitChannelComponent } from './direct-debit-channel/direct-debit-channel.component';
import { UssdChannelComponent } from './ussd-channel/ussd-channel.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SvgComponent } from './svg/svg.component';
import { QRCodeModule } from 'angularx-qrcode';
import { NibssQrChannelComponent } from './qr-channel/nibss-qr-channel/nibss-qr-channel.component';
import { VisaQrChannelComponent } from './qr-channel/visa-qr-channel/visa-qr-channel.component';

/**
 * Created by Stephen on 02/09/2022.
 */

@NgModule({
  declarations: [
    AppComponent,
    CardChannelComponent,
    TransferChannelComponent,
    DirectDebitChannelComponent,
    UssdChannelComponent,
    SvgComponent,
    NibssQrChannelComponent,
    VisaQrChannelComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxOtpInputModule,
    HttpClientModule,
    QRCodeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
