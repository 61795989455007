<ng-container *ngIf="!state.verifyingTransfer && !state.error">
  <div class="managedaccount__details" *ngIf="!state.error">
    <div class="managedaccount__message">
      <span>To complete your transaction, make the transfers to the following
        account</span>
    </div>
    <div>
      <div class="bank">{{bankName}}</div>
      <div class="account-copy">
        <div id="acc-no" class="account-number">
          {{ responseData?responseData.accountNumber: "1122233334" }}
        </div>
        <span  *ngIf="!copied" role="button" (click)="copyToClipboard()" class="ms-2 ">
          <span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1576_42778)">
                <path
                  d="M13.3333 6H7.33333C6.59695 6 6 6.59695 6 7.33333V13.3333C6 14.0697 6.59695 14.6667 7.33333 14.6667H13.3333C14.0697 14.6667 14.6667 14.0697 14.6667 13.3333V7.33333C14.6667 6.59695 14.0697 6 13.3333 6Z"
                  stroke="#4F499F" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
                <path
                  d="M3.33337 9.99998H2.66671C1.93033 9.99998 1.33337 9.40305 1.33337 8.66665V2.66665C1.33337 1.93027 1.93033 1.33331 2.66671 1.33331H8.66671C9.40311 1.33331 10 1.93027 10 2.66665V3.33331"
                  stroke="#4F499F" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_1576_42778">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </span>
          <span class="ms-1">Copy</span>
        </span>
        <span *ngIf="copied" class="ms-2">{{ copyMessage }}</span>
      </div>
      <div class="account-name fz-12">
        {{
        responseData ? responseData.accountName : "Stanbic Virtual Account"
        }}
      </div>
    </div>
  </div>

  <div class="confirm-payment">
    <button class="omni-btn omni-btn-primary" (click)="confirmTransaction()" [disabled]="state.error">
      I have paid {{ amount | currency: "NGN":"symbol-narrow" }}
    </button>
  </div>

  <div class="expiry-information">
    <span class="d-inline-block expiry-information__countdown"
      [ngStyle]="{ color: accountNumberExpired ? '#FF435E' : 'var(--primary)' }">
      {{
      accountNumberExpired ? "Account expired" : "Account expires in"
      }}
      &nbsp;
    </span>
    <span style="font-weight: bold; color: var(--primary); margin-right: 10px" *ngIf="!accountNumberExpired">{{ display
      }}</span>
    <span [ngClass]="{ 'error__icon-icon': accountNumberExpired }">
      <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6 0H12V2H6V0ZM16.03 6.39L17.45 4.97C17.02 4.46 16.55 3.98 16.04 3.56L14.62 4.98C13.0273 3.69833 11.0443 2.99969 9 3C6.61305 3 4.32387 3.94821 2.63604 5.63604C0.948211 7.32387 0 9.61305 0 12C0 16.97 4.02 21 9 21C10.6943 21.0009 12.3544 20.5232 13.7891 19.622C15.2238 18.7207 16.3748 17.4325 17.1094 15.9057C17.8441 14.379 18.1325 12.6758 17.9415 10.9923C17.7506 9.30874 17.088 7.71341 16.03 6.39ZM10 13H8V7H10V13Z" />
      </svg>
    </span>
  </div>

  <!-- <div class="error" *ngIf="state.error">
                        <span class="error__icon">
                          <svg width="18" height="21" viewBox="0 0 18 21" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M6 0H12V2H6V0ZM16.03 6.39L17.45 4.97C17.02 4.46 16.55 3.98 16.04 3.56L14.62 4.98C13.0273 3.69833 11.0443 2.99969 9 3C6.61305 3 4.32387 3.94821 2.63604 5.63604C0.948211 7.32387 0 9.61305 0 12C0 16.97 4.02 21 9 21C10.6943 21.0009 12.3544 20.5232 13.7891 19.622C15.2238 18.7207 16.3748 17.4325 17.1094 15.9057C17.8441 14.379 18.1325 12.6758 17.9415 10.9923C17.7506 9.30874 17.088 7.71341 16.03 6.39ZM10 13H8V7H10V13Z" />
                          </svg>
                        </span>
                        <div class="error__header">
                          <div class="error__message">Account number expired.</div>
                        </div>
                        <div class="resolution">
                          <div>
                            <div>
                              <div class="resolution">
                                <button class="omni-btn omni-btn-primary" (click)="tryAgain()">Try again</button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
</ng-container>

<ng-container *ngIf="state.error">
  <div class="omni-checkout-alert">
    <div class="text-center">
      <div class="custom-alert-icon mb-3">
        <svg-icon name="error-icon" svgClass="img-fluid w-50"></svg-icon>
      </div>
      <div class="px-3">
        <div class="fz-14 fw-bold pb-2">
          We could not process this transaction
        </div>
        <div class="w-full text-center pb-2 lh-2">
          <small class="fz-12">
            {{ errorMessage }}
          </small>
        </div>
      </div>
      <!-- <div class="mt-4">
                          <button (click)="tryAgain()" class="omni-btn omni-btn-transparent">Try again</button>
                        </div> -->
    </div>
  </div>
</ng-container>

<ng-container *ngIf="state.verifyingTransfer">
  <div class="verification-loader">
    <span class="omni-spinner-text">{{loadingMessage}}</span>
    <div class="omni-spinner-border" role="status"></div>
  </div>
</ng-container>