import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { timer } from 'rxjs';
import { TransactionData } from '../api-request';
import { ApiResponse, TransactionFees } from '../api-response';
import { AppService } from '../app.service';
import { StringHelper } from '../strings-helper';

@Component({
  selector: 'ussd-channel',
  templateUrl: './ussd-channel.component.html',
  styleUrls: ['./ussd-channel.component.scss'],
})
export class UssdChannelComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Output() onComplete: any = new EventEmitter<any>();
  @Output() onClose: any = new EventEmitter<any>();
  @Input() activeTab: any = null;
  error: boolean = false;

  state = {
    visible: false,
    isInitialized: false,
    completed: false,
    loaded: false,
    bankSelected: false,
    verifyingUssd: false,
    error: false,
  };
  responseData: any;
  bankOption: any = null;
  transactionData!: TransactionData;
  amount: any;
  transactionFees!: TransactionFees;
  reference!: string;
  errorMessage!: string;

  time_in_minutes = 5;

  display: string = `${5} minutes`;
  public timerInterval: any;
  ussdExpired: boolean = false;
  loadingMessage: string = 'Please wait';


  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  private timer$: any;

  constructor(
    private cd: ChangeDetectorRef,
    private appService: AppService,
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeTab'].currentValue == 'ussd-tab') {
      const data = {
        ussdRequestType: 'USSD_CODE_GENERATION',
        reference: this.reference,
      };
      this.cancelProcess();
      if (!this.state.visible) {
        this.generateCode(data);
      }
      
    }
  }

  ngAfterViewInit(): void {
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.transactionFees = res.transactionFees
        this.amount = StringHelper.toMajorAmount(+this.transactionData.amount + this.transactionFees.WEB_USSD);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  ngOnInit(): void {}

  formatTime(value: number) {
    `${Math.floor(value / 60)}:${('0' + (value % 60)).slice(-2)}`;
  }

  startCountDown() {
    this.ussdExpired = false;
    this.timer(this.time_in_minutes);
  }

  stopCountDown() {
    clearInterval(this.timerInterval);
  }

  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.ussdExpired = true;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  generateCode(data: any) {
    this.setState('error', false);
    this.setState('verifyingUssd', true);
    this.appService.generateUssdCode(data).subscribe({
      next: (res) => {
        if (res.success) {
          this.responseData = res.data.bankUssdCodes;
          this.setState('verifyingUssd', false);
          this.setState('visible', true);
        } else {
          this.setState('verifyingUssd', false);
          this.setState('error', true);
          this.errorMessage =
            'An error occurred while generating USSD code please try again';
        }
      },
      error: (err) => {
        console.log(err);
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingUssd', false);
        this.setState('error', true);
        this.stopCountDown();
      },
    });
  }

  confirmTransaction() {
    this.setState('verifyingUssd', true);
    const data = {
      ussdRequestType: 'USSD_STATUS_QUERY',
      reference: this.reference,
    };
    this.appService.queryUssdTransaction(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingUssd', false);
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          if (errorMessage == "PENDING_CONFIRMATION") {
            this.beginQuery();
            return;
          }
          this.setState('verifyingUssd', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingUssd', false);
        this.setState('error', true);
      },
    });
  }

  beginQuery() {
    this.startInterval();
  }

  startInterval() {
    this.timer$ = timer(0, 5000)
    .subscribe((tick: any) => {
      this.loadingMessage = `Confirming Transaction Status.`
      if (tick == 10) {
        this.loadingMessage = `It's taking longer than expected to confirm your payment.`
        this.errorMessage = `We could not confirm your payment.`
        setTimeout(() => {
          this.setState('verifyingUssd', false);
          this.setState('error', true);
        }, 5000);
      }
      else if (tick == 12) {
        this.stopInterval();
        // this.onClose.emit();
      }
      else {
      // Your API call, which will be performed every 5000 milliseconds
        this.queryTransaction();
      }
    });
  }

  stopInterval() {
    this.timer$.unsubscribe();
  }

  queryTransaction() {
    const data = {
      ussdRequestType: 'USSD_STATUS_QUERY',
      reference: this.reference,
    };
    this.appService.queryUssdTransaction(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingUssd', false);
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          if (errorMessage == "PENDING_CONFIRMATION") {
              return;
          }
          this.setState('verifyingUssd', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingUssd', false);
        this.setState('error', true);
      },
    });
  }

  cancelProcess() {
    this.setState('verifyingUssd', false);
    this.setState('bankSelected', false);
    this.setState('error', false);
    this.stopCountDown();
  }

  selectBank() {
    this.setState('bankSelected', true);
    this.startCountDown();
  }

  paymentDone() {
    this.onComplete.emit();
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
  }
}
