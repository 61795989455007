import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { timer } from 'rxjs';
import { TransactionData } from '../api-request';
import { ApiResponse, TransactionFees } from '../api-response';
import { AppService } from '../app.service';
import { ICopyToClipboard, StringHelper } from '../strings-helper';

@Component({
  selector: 'transfer-channel',
  templateUrl: './transfer-channel.component.html',
  styleUrls: ['./transfer-channel.component.scss'],
})
export class TransferChannelComponent
  implements OnInit, AfterViewInit, OnChanges, OnDestroy
{
  @Output() onComplete: any = new EventEmitter<any>();
  @Output() onClose: any = new EventEmitter<any>();
  @Input() activeTab: any = null;
  inProcess = true;

  // 20 minutes from now
  time_in_minutes = 20;

  display: string = `${20} minutes`;
  public timerInterval: any;

  state = {
    visible: false,
    isInitialized: false,
    completed: false,
    loaded: false,
    error: false,
    verifyingTransfer: false,
  };
  responseData: any;
  transactionData!: TransactionData;
  amount: any;
  transactionFees!: TransactionFees;
  copied: boolean = false;
  copyMessage: string = 'Copied!';
  reference!: string;
  errorMessage!: string;
  accountNumberExpired: boolean = false;
  loadingMessage: string = 'Please wait';
  bankName = "Stanbic IBTC Bank";

  private setState(key: any, value: any) {
    this.state = { ...this.state, [key]: value };
    this.cd.detectChanges();
  }

  private timer$: any;

  constructor(
    private cd: ChangeDetectorRef,
    private appService: AppService,
    private el: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.state.visible) {
      if (changes['activeTab'].currentValue == 'accounts-tab') {
        this.setTransaction(this.transactionData);
      }
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.appService.getData().subscribe({
      next: (res) => {
        this.transactionData = res.txnData;
        this.reference = res.txnRef;
        this.transactionFees = res.transactionFees
        this.amount = StringHelper.toMajorAmount(+this.transactionData.amount + this.transactionFees.WEB_TRANSFER);
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  setTransaction(r: TransactionData) {
    this.setState('verifyingTransfer', true);
    this.setState('error', false);
    const data = {
      vasRequestType: 'GENERATE_TEMPORARY_VIRTUAL_ACCOUNT',
      transactionRef: this.reference,
    };
    this.appService.generateVirtualAccount(data).subscribe({
      next: (res: any) => {
        if (res.success) {
          this.setState('verifyingTransfer', false);
          this.setState('visible', true);
          this.responseData = res.data;
          this.startCountDown();
        }
      },
      error: (err: any) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
        this.stopCountDown();
      },
    });
  }

  confirmTransaction() {
    this.setState('verifyingTransfer', true);
    const data = {
      vasRequestType: 'TRANSFER_PAYMENT_STATUS',
      transactionRef: this.reference,
    };
    this.appService.transferPaymentStatus(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingTransfer', false);
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          console.log(errorMessage);

          this.setState('verifyingTransfer', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        const errorCode = StringHelper.getErrorCode(err.error);
        if (errorCode == "PAY_ENG_012") {
          this.beginQuery();
          return;
        }
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  beginQuery() {
    this.startInterval();
  }

  startInterval() {
    this.timer$ = timer(0, 10000)
    .subscribe((tick: any) => {
      this.loadingMessage = `Confirming Transaction Status.`
      if (tick == 30) {
        this.loadingMessage = `It's taking longer than expected to confirm your transfer.`
        this.errorMessage = `We could not confirm your transfer.`
        setTimeout(() => {
          this.setState('verifyingTransfer', false);
          this.setState('error', true);
        }, 3000);
      }
      else if (tick == 32) {
        this.stopInterval();
        this.onClose.emit();
      }
      else {
      // Your API call, which will be performed every 10000 milliseconds
        this.queryTransaction();
      }
    });
  }

  stopInterval() {
    this.timer$.unsubscribe();
  }

  queryTransaction() {
    const data = {
      vasRequestType: 'TRANSFER_PAYMENT_STATUS',
      transactionRef: this.reference,
    };
    this.appService.transferPaymentStatus(data).subscribe({
      next: (res: ApiResponse) => {
        if (res.success) {
          this.setState('verifyingTransfer', false);
          this.paymentDone();
        } else {
          const errorMessage = StringHelper.getErrorMessage(res);
          console.log(errorMessage);

          this.setState('verifyingTransfer', false);
        }
      },
      error: (err) => {
        this.errorMessage = StringHelper.getErrorMessage(err.error);
        const errorCode = StringHelper.getErrorCode(err.error);
        if (errorCode == "PAY_ENG_012") {
            return;
        }
        this.setState('verifyingTransfer', false);
        this.setState('error', true);
      },
    });
  }

  paymentDone() {
    this.onComplete.emit();
    const domEvent = new CustomEvent('paymentDone');
    this.el.nativeElement.dispatchEvent(domEvent);
  }

  verifyTransfer() {
    this.setState('verifyingTransfer', true);
    setTimeout(() => {
      this.paymentDone();
      this.inProcess = false;
    }, 3000);
  }

  formatTime(value: number) {
    `${Math.floor(value / 60)}:${('0' + (value % 60)).slice(-2)}`;
  }

  startCountDown() {
    this.accountNumberExpired = false;
    this.timer(this.time_in_minutes);
  }

  stopCountDown() {
    clearInterval(this.timerInterval);
  }

  timer(minute: number) {
    // let minute = 1;
    let seconds: number = minute * 60;
    let textSec: any = '0';
    let statSec: number = 60;

    const prefix = minute < 10 ? '0' : '';

    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = '0' + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.accountNumberExpired = true;
        clearInterval(this.timerInterval);
      }
    }, 1000);
  }

  tryAgain() {
    this.stopCountDown();
    this.startCountDown();
  }

  copyToClipboard() {
    // Get the text field
    const copyText = (<HTMLElement>document.getElementById('acc-no')).innerHTML;
    const arg: ICopyToClipboard = {
      target: 'acc-no',
      value: copyText,
      message: this.copyMessage,
    };
    StringHelper.copyToClipboard(arg).then((res) => {
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    });
  }

  ngOnDestroy(): void {
    this.timer$?.unsubscribe();
  }
}
