/**
 * Created by Stephen on 20/09/2022.
 */

export interface ApiResponse {
  success: boolean;

  data: any;

  message: string;

  errors: Array<any>;

  statusCode: number;
}

export class TransactionFees {
  WEB_CARD : number;
  WEB_USSD : number;
  WEB_QR : number;
  WEB_TRANSFER : number;

  constructor(obj?: any) {
    this.WEB_CARD = (obj && obj.WEB_CARD) || 0;
    this.WEB_USSD = (obj && obj.WEB_USSD) || 0;
    this.WEB_QR = (obj && obj.WEB_QR) || 0;
    this.WEB_TRANSFER = (obj && obj.WEB_TRANSFER) || 0;
  }
}